import { useCallback, useEffect, useRef, useState } from "react";

const App = () => {
  const [openAside, setOpenAside] = useState(false);
  const [activeVideo, setActiveVideo] = useState(false);
  const reproductor = useRef();
  const aboutVideo = useRef();

  const [hero, setHero] = useState({
    title: "Grupo San Cipriano",
    texts: [
      <>
        El Grupo San Cipriano es un holding de empresas venezolanas que
        satisfacen las necesidades de sus clientes en diversos rubros; como
        importación y comercialización de maquinarias para el uso agrícola,
        además de piedra pulida de Mármol y Granito de distintas gamas y de gran
        calidad.
      </>,
      <>
        Entre sus servicios también destacan la fabricación y empaque de
        productos lácteos, jugos y néctares, así como la importación,
        distribución y comercialización de alimentos de consumo masivo.
      </>,
      <>
        Grupo San Cipriano, inicia sus actividades el 8 de mayo del año 2009,
        fundamentado en el sueño de una familia para quienes el esfuerzo, la
        ayuda al prójimo y la constancia en el trabajo, formaban parte de sus
        principios de vida.
      </>,
    ],
    video: "assets/video/video-de-grupo.mp4",
  });

  const [aboutArea, setAboutArea] = useState([
    {
      image: "assets/images/ganaderia/1.png",
      title: (
        <>
          División de agropecuaria
          <br />
          San Cipriano
        </>
      ),
      texts: [
        <>
          La división de agropecuaria San Cipriano, está conformada por fincas
          de cultivo, cría y engorde de ganado en más de <strong>1000</strong>{" "}
          hectáreas productivas, ubicadas en el estado <strong>Yaracuy</strong>.
        </>,
        <>
          Cumpliendo con los diferentes procesos, recolección, transporte y
          controles internos, para garantizar la{" "}
          <strong>máxima productividad</strong>.
        </>,
      ],
    },
  ]);

  const [slogan, setSlogan] = useState({
    title: "Calidad & Servicio",
    texts: [
      <>
        Nuestro compromiso es ofrecer{" "}
        <strong>productos y servicios de calidad</strong>, con el más alto
        estándar de calidad, para <strong>satisfacer las necesidades</strong> de
        nuestros clientes."
      </>,
    ],
  });

  const [nosotros, setNosotros] = useState({
    title: `Nuestro enfoque se basa en mantener las bases sólidas, y esforzarnos para lograr que a
    diario nuestra misión, visión y valores organizacionales se cumplan. No
    es suficiente solo establecer premisas, sino fomentar que la actitud, y
    nivel de servicio impactan sobre los resultados de la compañía. “Cuando
    la misión y visión toman vida, agregan valor a la compañía”.`,
    texts: [
      <></>,
      // "Nuestro equipo de trabajo, está conformado por profesionales de la salud, veterinaria y nutrición, que garantizan la calidad de nuestros productos.",
    ],
    marcas: [
      {
        image: "assets/images/mision.png",
        title: "Nuestra Misión",
        texts: [
          <>
            Somos una estructura sólida en la distribución de alimentos a nivel
            nacional, brindando productos de alta calidad y excelencia en el
            servicio.
          </>,
        ],
      },
      {
        image: "assets/images/vision.png",
        title: "Nuestra Visión",
        texts: [
          <>
            Colocar nuestros productos al alcance de los consumidores con el
            propósito de permanecer en cada hogar venezolano.
          </>,
        ],
      },
      {
        image: "assets/images/valores.png",
        title: "Nuestros Valores",
        texts: [
          <div className="row">
            <ul className="col-12">
              <li
                className="py-1"
                title=" La empresa deberá asegurarse de que todos los alimentos distribuidos cumplan con los estándares de calidad exigidos por la ley. Esto significa supervisar el proceso de producción, incluyendo el control de calidad y el rastreo de los alimentos."
              >
                ⊛ Honestidad
              </li>
              <li
                className="py-1"
                title="El proceso de distribución de alimentos debe ser sostenible, asegurando que la empresa no agote los recursos naturales y respete el medio ambiente. Esto incluye la adopción de prácticas como el uso de empaques reciclables, el uso de energía renovable y el reciclaje."
              >
                ⊛ Integridad
              </li>
              <li
                className="py-1"
                title="La empresa debe garantizar la seguridad alimentaria, asegurándose de que los alimentos distribuidos no contengan sustancias tóxicas y estén en las mejores condiciones para su consumo. Esto implica el uso de controles de calidad exhaustivos y una estricta limpieza de los almacenes."
              >
                ⊛ Calidad en los procesos
              </li>
              <li
                className="py-1"
                title="Compromiso de llevar a cabo sus operaciones de forma entregable y ética con el objetivo de contribuir al bienestar social. Esto puede incluir el compromiso de respetar los derechos humanos, el empleo responsable, el medio ambiente, el comercio ético, la salud y la seguridad de los trabajadores, la lucha contra el racismo y la discriminación y el apoyo a la comunidad."
              >
                ⊛ Pasión
              </li>
              <li
                className="py-1"
                title="Los proveedores de alimentos deben tratar con respeto a los trabajadores de todos los niveles, sin discriminación o explotación."
              >
                ⊛ Dedicación
              </li>
              <li
                className="py-1"
                title="Los proveedores de alimentos deben tratar con respeto a los trabajadores de todos los niveles, sin discriminación o explotación."
              >
                ⊛ Sentido de pertenencia
              </li>
            </ul>
          </div>,
        ],
      },
    ],
  });

  const [diversificado, setDiversificado] = useState({
    title: "Unidades de negocios",
    texts: [
      <>
        Líderes en importación, comercialización y distribución, orientados a
        proveer constantemente a nuestros clientes y consumidores nuestros
        productos, unificando innovación, esfuerzo y profesionalismo.
      </>,
    ],
    marcas: [
      {
        image: "assets/images/ganaderia/san-cipriano.png",
        title: "San Cipriano",
        texts: [
          <>
            Empresa de origen larense fundada en el año 2019, dedicada a la
            importación, comercialización y distribución de alimentos de consumo
            masivo, con el propósito firme de formar parte de la vida de todos
            los venezolanos.
          </>,
          <>
            Actualmente contamos con 11 sucursales en el territorio venezolano,
            con el objetivo de estar cada día Más cerca de ti
          </>,
        ],
      },
      {
        image: "assets/images/ganaderia/ls-tractor.png",
        title: "LS Tractor",
        texts: [
          <>
            Grupo SC son los Representantes exclusivos en Venezuela de la marca
            LS Tractor con su amplia gama de modelos de tractores y equipamiento
            que se adaptan a las necesidades de cada cliente.
          </>,
          <>
            Inició sus actividades agrícolas en 1976, como un grupo de la
            familia LG, en Corea, y se ha destacado en su territorio como la
            empresa líder en el negocio de máquinas y componentes electrónicos.
            La empresa forma parte del grupo LS.
          </>,
        ],
      },
      {
        image: "assets/images/ganaderia/lacteos-yaracuy.png",
        title: "Lacteos Yaracuy",
        texts: [
          <>
            Esta marca Lácteos Yaracuy de importante posicionamiento en el
            mercado venezolano fundada en 2002, continua en la memoria de todos
            los venezolanos, se dedica a la producción de lácteos y sus
            derivados.
          </>,
        ],
      },
    ],
  });

  const [translado, setTranslado] = useState({
    title: <>Traslado a Empalact</>,
    texts: [
      <>
        La división de la fabricación de productos a través de{" "}
        <strong>EMPALACT</strong>, esta planta cuenta con la capacidad instalada
        para procesar derivados lácteos, así como también líneas de producción
        para <strong>empaquetado de alimentos</strong>.
      </>,
      <>
        Con un alto estándar de infraestructura, calidad en los procesos,
        estandarización y controles de fase, garantizamos un{" "}
        <strong>servicio de alta calidad</strong>.
      </>,
    ],
  });

  const playVideo = () => {
    setActiveVideo(true);
    reproductor.current.play();
    reproductor.current.volume = 1;
  };

  const playVideoAbout = () => {
    aboutVideo.current.play();
    aboutVideo.current.volume = 1;
  };

  const handleVideo = () => {
    if (reproductor.current.paused) {
      reproductor.current.play();
    } else {
      reproductor.current.pause();
    }
  };

  const handleVideoAbout = () => {
    if (aboutVideo.current.paused) {
      aboutVideo.current.play();
    } else {
      aboutVideo.current.pause();
    }
  };

  const handleScroll = useCallback(() => {
    if (window.scrollY > 220 && activeVideo) {
      setActiveVideo(false);
      reproductor.current.volume = 0.05;
    }
  }, [activeVideo]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <div>
      <section className="navbar-area navbar-nine">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="/">
                  <img
                    width={"270px"}
                    src="assets/images/white-grupo-san-cipriano.png"
                    alt="Logo"
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNine"
                  aria-controls="navbarNine"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                </button>
                <div
                  style={{ flexDirection: "row", justifyContent: "flex-end" }}
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarNine"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="page-scroll active" href="#hero-area">
                        Inicio
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#team">
                        Marcas
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#acerca">
                        Nosotros
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#contact">
                        Contacto
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="navbar-btn d-none d-lg-inline-block">
                  <a
                    onClick={() => setOpenAside(true)}
                    className="menu-bar"
                    href="#"
                  >
                    <i className="lni lni-menu" />
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <div className={`sidebar-left ${openAside ? "open" : ""}`}>
        <div onClick={() => setOpenAside(false)} className="sidebar-close">
          <a className="close" href="#">
            <i className="lni lni-close" />
          </a>
        </div>
        <div className="sidebar-content">
          <div className="sidebar-logo">
            <a href="index.html">
              <img
                src="assets/images/logo-grupo-san-cipriano.jpeg"
                alt="Logo"
              />
            </a>
          </div>
          <p className="text">
            Somos un grupo de empresas de diversos sectores que ofrecen
            productos y servicios con los mas altos estándares de calidad,
            apostando siempre a Venezuela y su talento humano.
          </p>
          <div className="sidebar-menu">
            <h5 className="menu-title">Mapa del sitio</h5>
            <ul>
              <li>
                <a href="#hero-area">Inicio</a>
              </li>
              <li>
                <a href="#team">Marcas</a>
              </li>
              <li>
                <a href="#acerca">Nosotros</a>
              </li>
              <li>
                <a href="#contact">Contacto</a>
              </li>
            </ul>
          </div>
          <div className="sidebar-social align-items-center justify-content-center">
            <h5 className="social-title">Síguenos en nuestra redes</h5>
            <ul>
              <li>
                <a href="#">
                  <i className="lni lni-facebook-filled" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="lni lni-twitter-original" />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/sanciprianove">
                  <i className="lni lni-instagram-original" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="lni lni-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        onClick={() => setOpenAside(false)}
        className={`overlay-left ${openAside ? "open" : ""}`}
      />

      <section id="hero-area" className="header-area header-eight">
        <div className="container">
          <div className={`row align-items-center justify-content-end`}>
            <div
              className={`transited col-lg-6 col-md-12 col-12 ${
                activeVideo ? "d-none" : ""
              }`}
            >
              <div className="header-content">
                <h1>{hero.title}</h1>
                {hero.texts.map((text, i) => (
                  <p key={i} style={{ textAlign: "justify" }}>
                    {text}
                  </p>
                ))}
                <div className="button">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      playVideo();
                    }}
                    className="glightbox video-button cursor-pointer"
                  >
                    <span className="btn icon-btn rounded-full">
                      <i className="lni lni-play" />
                    </span>
                    <span className="text">{"Ver video"}</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className={`transited col-lg-${
                activeVideo ? "12" : "6"
              } col-md-12 col-12`}
            >
              <div className="header-image bg-gray">
                <video
                  onClick={handleVideo}
                  style={{ borderRadius: "7px", cursor: "pointer" }}
                  ref={reproductor}
                  src={hero.video}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="latest-news-area section">
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <img src="assets/images/business-text.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {diversificado.marcas.map((item, i) => (
              <div key={i} className="col-lg-4 col-md-6 col-12">
                <div
                  className="single-news"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <a
                    href="#"
                    style={{
                      marginBottom: item.title === "LS Tractor" ? 79 : 40,
                      padding: 20,
                    }}
                  >
                    <img
                      width={"100%"}
                      height={item.title === "LS Tractor" ? 150 : 190}
                      className="thumb"
                      src={item.image}
                      alt="Blog"
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  </a>

                  <div className="content-body">
                    {item.texts.map((text, i) => (
                      <p
                        key={i}
                        className="mb-3"
                        style={{ textAlign: "justify" }}
                      >
                        {text}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="map" className="latest-news-area-white section">
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h2 className="fw-bold">Nuestras sucursales</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <img
              width={"100%"}
              height={"100%"}
              className="thumb"
              src={`assets/images/mapa.jpg`}
              alt="Blog"
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
      </section>

      <section id="acerca" className="services-area services-eight section">
        <div className="container" style={{ maxWidth: 1100, marginBottom: 40 }}>
          <div className="row">
            <div className="col-12">
              <div className="content">
                <img src="assets/images/mision-text.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {nosotros.marcas.map((marca, i) => (
              <div
                key={i}
                className="col-lg-4 col-md-6"
                style={{ cursor: "pointer" }}
              >
                <div className="single-services" style={{ height: 420 }}>
                  {/* <div className="">
                    <div className="service-icon">
                      <img src={marca.image} alt="" width={"50px"} />
                    </div>
                  </div> */}
                  <div className="service-content">
                    <img src={marca.image} alt="" />
                    {/* {marca.texts.map((text, i) => (
                      <p
                        style={{ textAlign: "justify" }}
                        key={i}
                        className="mb-3"
                      >
                        {text}
                      </p>
                    ))} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <section id="call-action" className="call-action">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="inner-content">
                {slogan.texts.map((text, i) => (
                  <p key={i} className="mb-3">
                    {text}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="about-area about-five">
        {aboutArea.map((item, i) => (
          <>
            <div key={i} className={`container ${i > 0 ? "mt-5 pt-5" : ""}`}>
              <div className={"row align-items-center"}>
                <div
                  className={`col-lg-6 col-12 ${
                    Boolean(i % 2) ? "order-1" : ""
                  }`}
                >
                  <div className="about-image-five">
                    {/* <svg
                      className="shape"
                      width={106}
                      height={134}
                      viewBox="0 0 106 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="1.66654"
                        cy="1.66679"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy="16.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy="31.0001"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy="45.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy="60.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy="88.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy="117.667"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy="74.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy={103}
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="1.66654"
                        cy={132}
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="16.3333"
                        cy="1.66679"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="16.3333"
                        cy="16.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="16.3333"
                        cy="31.0001"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="16.3333"
                        cy="45.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="16.333"
                        cy="60.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="16.333"
                        cy="88.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="16.333"
                        cy="117.667"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="16.333"
                        cy="74.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle cx="16.333" cy={103} r="1.66667" fill="#DADADA" />
                      <circle cx="16.333" cy={132} r="1.66667" fill="#DADADA" />
                      <circle
                        cx="30.9998"
                        cy="1.66679"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="74.6665"
                        cy="1.66679"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="30.9998"
                        cy="16.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="74.6665"
                        cy="16.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="30.9998"
                        cy="31.0001"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="74.6665"
                        cy="31.0001"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="30.9998"
                        cy="45.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="74.6665"
                        cy="45.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle cx={31} cy="60.3335" r="1.66667" fill="#DADADA" />
                      <circle
                        cx="74.6668"
                        cy="60.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle cx={31} cy="88.6668" r="1.66667" fill="#DADADA" />
                      <circle
                        cx="74.6668"
                        cy="88.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle cx={31} cy="117.667" r="1.66667" fill="#DADADA" />
                      <circle
                        cx="74.6668"
                        cy="117.667"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle cx={31} cy="74.6668" r="1.66667" fill="#DADADA" />
                      <circle
                        cx="74.6668"
                        cy="74.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle cx={31} cy={103} r="1.66667" fill="#DADADA" />
                      <circle
                        cx="74.6668"
                        cy={103}
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle cx={31} cy={132} r="1.66667" fill="#DADADA" />
                      <circle
                        cx="74.6668"
                        cy={132}
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy="1.66679"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy="1.66679"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy="16.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy="16.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy="31.0001"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy="31.0001"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy="45.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy="45.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy="60.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy="60.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy="88.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy="88.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy="117.667"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy="117.667"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy="74.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy="74.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy={103}
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy={103}
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="45.6665"
                        cy={132}
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="89.3333"
                        cy={132}
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="60.3333"
                        cy="1.66679"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx={104}
                        cy="1.66679"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="60.3333"
                        cy="16.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx={104}
                        cy="16.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="60.3333"
                        cy="31.0001"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx={104}
                        cy="31.0001"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="60.3333"
                        cy="45.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx={104}
                        cy="45.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="60.333"
                        cy="60.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx={104}
                        cy="60.3335"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="60.333"
                        cy="88.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx={104}
                        cy="88.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="60.333"
                        cy="117.667"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx={104}
                        cy="117.667"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx="60.333"
                        cy="74.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle
                        cx={104}
                        cy="74.6668"
                        r="1.66667"
                        fill="#DADADA"
                      />
                      <circle cx="60.333" cy={103} r="1.66667" fill="#DADADA" />
                      <circle cx={104} cy={103} r="1.66667" fill="#DADADA" />
                      <circle cx="60.333" cy={132} r="1.66667" fill="#DADADA" />
                      <circle cx={104} cy={132} r="1.66667" fill="#DADADA" />
                    </svg> */}
                    {/* <img src={item.image} alt="about" /> */}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="about-five-content">
                    <h2
                      className="main-title fw-bold"
                      style={{ color: "white" }}
                    >
                      {item.title}
                    </h2>
                    <div className="about-five-tab mt-2">
                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="nav-who"
                          role="tabpanel"
                          aria-labelledby="nav-who-tab"
                        >
                          {item.texts.map((text, i) => (
                            <p
                              key={i}
                              style={{ textAlign: "justify", color: "white" }}
                            >
                              {text}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </section>

      <section id="contact" className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="contact-item-wrapper">
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-phone" />
                      </div>
                      <div className="contact-content">
                        <h4>Contacto</h4>
                        <p>0251 2691847 / 0251 2692269</p>
                        <p>info@gruposancipriano.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-map-marker" />
                      </div>
                      <div className="contact-content">
                        <h4>Dirección</h4>
                        <p>Calle 6 carreras 5 y 6, Barquisimeto, Lara</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-alarm-clock" />
                      </div>
                      <div className="contact-content">
                        <h4>Horario</h4>
                        <p>Lunes - Viernes</p>
                        <p>08:00 AM - 05:30 PM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="contact-form-wrapper">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 mx-auto">
                    <div className="section-title text-center">
                      <span> Contactenos</span>
                      <h2>Ingresa tu información</h2>
                      <p>
                        Rellena tus datos de contacto y te escribiremos en la
                        brevedad prosible
                      </p>
                    </div>
                  </div>
                </div>
                <form action="#" className="contact-form">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nombre completo"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Teléfono"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="subject"
                        id="email"
                        placeholder="Asunto"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Ingresa tu mensaje"
                        rows={5}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="button text-center rounded-buttons">
                        <button
                          type="submit"
                          className="btn primary-btn rounded-full"
                        >
                          Enviar mensaje
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="map-section map-style-9">
        <div className="map-container">
          <object
            style={{ border: 0, height: 500, width: "100%" }}
            data="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3928.236355182877!2d-69.37727988520516!3d10.079709892796545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDA0JzQ3LjAiTiA2OcKwMjInMzAuMyJX!5e0!3m2!1sen!2sve!4v1675874138062!5m2!1sen!2sve"
          />
        </div>
      </section>

      <footer className="footer-area" style={{ padding: 20 }}>
        <p style={{ textAlign: "center" }}>
          <span>© 2023 Grupo San Cipriano. Todos los derechos reservados</span>
        </p>
      </footer>

      <a href="#" className="scroll-top btn-hover">
        <i className="lni lni-chevron-up" />
      </a>
    </div>
  );
};

export default App;
